<ui-modal [modalTitle]="title">

	<ng-container>
		<ui-form labelWidth="6rem">
			<ui-form-control label="Export Type">
				<select class="form-control" [(ngModel)]="selectedType" [ngModelOptions]="{ standalone: true }">
					<option [ngValue]="null">Please Select</option>
					<optgroup [label]="'All ' + service.moduleMap[app.routeData.module]" >
						<option value="quotes">{{service.moduleMap[app.routeData.module]}} Details</option>
						<option *ngIf="context === 'quote'" value="lines">{{service.moduleMap[app.routeData.module]}} Lines</option>
						<option *ngIf="app.orgInfo?.allow_export_quote_all && context !== 'quote'" value="lines-all">{{service.moduleMap[app.routeData.module]}} Lines (All Stages)</option>
						<option value="labour">Labour</option>
						<option value="labour-by-type">Labour By Type</option>
					</optgroup>
					<optgroup *ngIf="context !== 'quote'" label="Accepted Quotes">
						<option value="lines">{{service.moduleMap[app.routeData.module]}} Lines</option>
						<option value="lines-limited-fields">{{service.moduleMap[app.routeData.module]}} Lines With Limited Fields</option>
					</optgroup>
				</select>
			</ui-form-control>
			<ng-container *ngIf="context === 'all'">
				<ui-form-control label="From">
					<p-calendar [(ngModel)]="start" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" ngDefaultControl></p-calendar>
				</ui-form-control>
				<ui-form-control label="To">
					<p-calendar [(ngModel)]="end" [ngModelOptions]="{ standalone: true }" [dateFormat]="app.calendarDateFormat" inputStyleClass="form-control w-7r" ngDefaultControl></p-calendar>
				</ui-form-control>
			</ng-container>
		</ui-form>
	</ng-container>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
	<button class="btn btn-primary" (click)="generate()" [disabled]="!shouldSubmit()" ui-modal-footer>Continue</button>

</ui-modal>
