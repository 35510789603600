import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApiService } from './api.service';
import { AppService } from './app.service';

@Injectable({
	providedIn: 'root'
})
export class QuoteGuardService implements CanActivate {

	constructor(
		private app: AppService,
		private api: ApiService,
		private router: Router
	) { }

	canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return new Promise((resolve) => {
			const org = route.params['org'] || null;
			const quote_id = route.params['quote'] || null;
			const module = route.data['module'] || 'quote';
			const path = route.data['path'] || 'summary';

			if (quote_id) {
				this.api.quote.isSalesOrder(quote_id, (data: boolean) => {
					if (module === 'quote' && data) {
						// Wrong module for sales orders, redirect
						resolve(this.router.parseUrl(`/${org}/sales-order/${quote_id}/${path}`));
					} else if (module === 'sales-order' && !data) {
						// Wrong module for quotes, redirect
						resolve(this.router.parseUrl(`/${org}/quote/${quote_id}/${path}`));
					} else {
						// Correct module, pass
						resolve(true);
					}
				}, error => {
					// Failed to resolve type, show error an load module root
					this.app.notifications.showDanger(error.message);
					resolve(this.router.parseUrl(`/${org}/${module}`));
				});
			} else {
				// Nothing to check, pass
				resolve(true);
			}
		});
	}

}
