<ui-modal [modalTitle]="title" size="xl" [footer]="true">
	<button *ngFor="let m of modes" class="btn border-0 mr-2" [ngClass]="{ 'btn-primary': mode === m.id, 'btn-outline-secondary': mode !== m.id }" (click)="selectMode(m.id)" ui-modal-header><i class="{{m.icon}} mr-2"></i>{{m.description}}</button>
	<button *ngIf="multiSelect" class="btn border-0 mr-2" [ngClass]="{ 'btn-primary': mode === 'selected', 'btn-outline-secondary': mode !== 'selected' }" (click)="selectMode('selected')" ui-modal-header>Selected <ui-button-badge>{{multiSelectList.length}}</ui-button-badge></button>

	<ng-container *ngIf="mode !== 'selected'">
		<div class="input-group mb-2 flex-weight-1">
			<div class="input-group-prepend"><span class="input-group-text"><i class="wq wq-search"></i></span></div>
			<input *ngIf="list && modal?.isOpen" type="search" class="form-control" placeholder="Search" [(ngModel)]="search" autocomplete="chrome-off" spellcheck="false" appDesktopFocus>
		</div>

		<div class="mb-2 scrollable" style="height: 500px;">
			<table *ngIf="list" class="table table-hover vam">
				<thead>
					<tr>
						<th>#</th>
						<th>Customer</th>
						<th class="nowrap">Assigned to</th>
						<th>Created</th>
						<th>Last Modified</th>
						<th>Location</th>
						<th class="shrink text-center">Status</th>
						<th class="nowrap text-right">Net total</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of list | keywords:search:['id', 'description', 'project_description', 'customer_name', 'posttown', 'postcode', 'user_name', 'stage', 'quote_no'] | pagination:pagination" class="selectable" [ngClass]="{ 'table-success': isItemSelected(item), 'table-danger': item.archived || item.has_expired }" (click)="selectItem(item)">
						<td class="text-secondary">{{item.quote_no}}</td>
						<td>
							<div>
								<b>{{item.customer_name}}</b>
								<ng-container *ngIf="item.project_description">
									<span class="text-secondary">/</span>
									<b class="text-info"> {{item.project_description}}</b>
								</ng-container>
							</div>
							<div class="text-secondary"><span *ngIf="item.is_sample" class="badge badge-warning">Sample</span> {{item.description}} <span *ngIf="item.revision > 1 || item.change_order" class="badge badge-secondary">R{{item.revision}}{{item.change_order ? '.' + item.change_order : ''}}</span></div>
						</td>
						<td><i class="wq mr-2" [ngClass]="{ 'wq-lock-open text-silver': item.is_public, 'wq-lock-closed text-secondary': !item.is_public }" ngbTooltip="Quote is {{item.is_public ? 'public' : 'private'}}"></i>{{item.user_name}}</td>
						<td>{{item.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}}</td>
						<td>
							{{item.modified_datetime | mySQLDateToISO | localizedDate:'shortDate'}}<br>
							<small class="text-secondary">{{item.modified_text}}</small>
						</td>
						<td>{{item.posttown}}</td>
						<td class="shrink nowrap text-center pb-0">
							<div class="badge badge-{{stageColor(item.stage)}} d-block" [ngbTooltip]="item.stage === 'sent' ? formattedSQLDate(item.quote_date) : null">{{stageDescription(item.stage)}}</div>
							<div *ngIf="item.stage === 'sent' && item.expiry_date" class="text-small text-purple text-bold mt-1">Exp: {{item.expiry_date | mySQLDateToISO | localizedDate:'shortDate'}}</div>
							<div *ngIf="item.stage === 'accepted'" class="text-small text-success text-bold mt-1">{{item.accepted_date | mySQLDateToISO | localizedDate:'shortDate'}}</div>
						</td>
						<td class="text-right">
							<i *ngIf="item.has_cost_warning" class="wq wq-warning text-danger mr-1" ngbTooltip="Cost issues"></i>
							<i *ngIf="item.has_price_change" class="wq wq-warning text-warning mr-1" ngbTooltip="Price updates"></i>
							<ng-container *ngIf="item.grand_total !== null">
								{{item.grand_total | decimal:2:2:true:item.currency_id}}
							</ng-container>
						</td>
					</tr>
					<tr *ngIf="!pagination.count">
						<td colspan="8" class="text-secondary">No {{type === 'sales_order' ? 'sales orders' : 'quotes'}} found.</td>
					</tr>
				</tbody>
			</table>

			<div *ngIf="!list" class="w-100 h-100 d-flex align-items-center justify-content-center">
				<div class="spinner-border text-secondary"></div>
			</div>
		</div>
		<app-pagination [ngClass]="{ 'invisible': !list }" [pagination]="pagination"></app-pagination>
	</ng-container>

	<ng-container *ngIf="mode === 'selected'">
		<div class="mb-2 scrollable" style="height: 500px;">
			<table *ngIf="multiSelectList.length" class="table table-hover vam">
				<thead>
					<tr>
						<th>#</th>
						<th>Customer</th>
						<th class="nowrap">Assigned to</th>
						<th>Created</th>
						<th>Last Modified</th>
						<th>Location</th>
						<th class="shrink text-center">Status</th>
						<th class="nowrap text-right">Net total</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of multiSelectList" class="selectable table-success" [ngClass]="{'table-danger': item.archived || item.has_expired }" (click)="selectItem(item)">
						<td class="text-secondary">{{item.quote_no}}</td>
						<td>
							<div>
								<b>{{item.customer_name}}</b>
								<ng-container *ngIf="item.project_description">
									<span class="text-secondary">/</span>
									<b class="text-info"> {{item.project_description}}</b>
								</ng-container>
							</div>
							<div class="text-secondary"><span *ngIf="item.is_sample" class="badge badge-warning">Sample</span> {{item.description}} <span *ngIf="item.revision > 1 || item.change_order" class="badge badge-secondary">R{{item.revision}}{{item.change_order ? '.' + item.change_order : ''}}</span></div>
						</td>
						<td><i class="wq mr-2" [ngClass]="{ 'wq-lock-open text-silver': item.is_public, 'wq-lock-closed text-secondary': !item.is_public }" ngbTooltip="Quote is {{item.is_public ? 'public' : 'private'}}"></i>{{item.user_name}}</td>
						<td>{{item.created_datetime | mySQLDateToISO | localizedDate:'shortDate'}}</td>
						<td>
							{{item.modified_datetime | mySQLDateToISO | localizedDate:'shortDate'}}<br>
							<small class="text-secondary">{{item.modified_text}}</small>
						</td>
						<td>{{item.posttown}}</td>
						<td class="shrink nowrap text-center pb-0">
							<div class="badge badge-{{stageColor(item.stage)}} d-block" [ngbTooltip]="item.stage === 'sent' ? formattedSQLDate(item.quote_date) : null">{{stageDescription(item.stage)}}</div>
							<div *ngIf="item.stage === 'sent' && item.expiry_date" class="text-small text-purple text-bold mt-1">Exp: {{item.expiry_date | mySQLDateToISO | localizedDate:'shortDate'}}</div>
							<div *ngIf="item.stage === 'accepted'" class="text-small text-success text-bold mt-1">{{item.accepted_date | mySQLDateToISO | localizedDate:'shortDate'}}</div>
						</td>
						<td class="text-right">
							<i *ngIf="item.has_cost_warning" class="wq wq-warning text-danger mr-1" ngbTooltip="Cost issues"></i>
							<i *ngIf="item.has_price_change" class="wq wq-warning text-warning mr-1" ngbTooltip="Price updates"></i>
							<ng-container *ngIf="item.grand_total !== null">
								{{item.grand_total | decimal:2:2:true:item.currency_id}}
							</ng-container>
						</td>
					</tr>
				</tbody>
			</table>
			<p *ngIf="!multiSelectList.length" class="text-secondary">No quotes selected.</p>
		</div>
	</ng-container>

	<button class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Cancel</button>
	<button *ngIf="multiSelect" class="btn btn-success" (click)="addQuotesButton()" [disabled]="!multiSelectList.length" ui-modal-footer><i class="wq wq-add mr-1"></i>Add Selected Quotes <ui-button-badge>{{multiSelectList.length}}</ui-button-badge></button>
</ui-modal>
