import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { ModalSelectProductComponent } from 'app/modal/modal-select-product/modal-select-product.component';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalEditCategoryGroupComponent } from '../modal-edit-category-group/modal-edit-category-group.component';
import { ModalEditContentComponent } from '../modal-edit-content/modal-edit-content.component';
import { ModalEditProductComponent } from '../modal-edit-product/modal-edit-product.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-category',
	templateUrl: './modal-edit-category.component.html',
	styleUrls: ['./modal-edit-category.component.scss']
})
export class ModalEditCategoryComponent extends UIModalBaseComponent {

	@ViewChild(ModalSelectProductComponent) productSelectModal: ModalSelectProductComponent;
	@ViewChild(ModalEditContentComponent) editContentModal: ModalEditContentComponent;
	@ViewChild(ModalEditCategoryGroupComponent) editCategoryGroupModal: ModalEditCategoryGroupComponent;
	@ViewChild(ModalEditProductComponent) editProductModal: ModalEditProductComponent;

	tabDetails = { id: 'details', description: 'Details' };
	tabToolkit = { id: 'toolkit', description: 'Toolkit' };

	tabs: any[] = [];
	selectedTab = 'details';

	id;
	quoteId;
	data;
	headerList = [];
	disabled = false;

	canDelete = false;
	canArchive = false;
	canUnarchive = false;

	title = '';

	editedProduct = null;

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.id = mdata.id || 'new';
		this.quoteId = mdata.quoteId || null;
		const categoryGroupId = parseInt(mdata.groupId, 10) || 0;
		this.data = null;
		this.disabled = false;
		this.canDelete = false;
		this.canArchive = false;
		this.canUnarchive = false;
		this.selectedTab = mdata.toolkit ? 'toolkit' : 'details';

		this.tabs = [this.tabDetails];
		if (this.app.orgInfo?.role.update_toolkit) this.tabs.push(this.tabToolkit);

		const success = data => {
			this.data = data;
			this.title = this.data.record.id === 'new' ? 'New Subsystem' : this.data.record.description;

			if (this.data.record.id === 'new' && categoryGroupId) this.data.record.category_group_id = categoryGroupId;
			if (!this.data.record.category_group_id) this.data.record.category_group_id = this.data.groups[0] ? this.data.groups[0].id : null;

			// Check if record can be deleted
			if (this.quoteId) {
				this.canDelete = false;
				this.canArchive = false;
				this.canUnarchive = false;
			} else {
				if (this.data.record.archived) {
					this.canUnarchive = true;
				} else if (this.id !== 'new') {
					this.api.category.canDelete(this.id, canDelete => {
						this.canDelete = canDelete;
						this.canArchive = !canDelete;
					});
				}
			}

			this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		this.api.template.listHeaders(list => {
			this.headerList = list;
			if (this.id === 'new') {
				this.api.category.new(categoryGroupId, success, fail);
			} else {
				this.api.category.get(this.id, this.quoteId, success, fail);
			}
		}, fail);
	}

	refreshDropDowns(successCallback = null) {
		const success = data => {
			this.data.groups = data.groups;
			successCallback?.();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
		};

		if (this.id === 'new') {
			this.api.category.new(this.data.category_group_id, success, fail);
		} else {
			this.api.category.get(this.id, this.quoteId, success, fail);
		}
	}

	deleteCategory() {
		if (!this.canDelete) return;

		this.app.dialog.pop('Delete Subsystem', 'Are you sure you want to delete this subsystem?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.category.delete(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Subsystem deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	archiveCategory() {
		if (!this.canArchive) return;

		this.app.dialog.pop('Delete Subsystem', 'Are you sure you want to delete this subsystem?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) {
				this.disabled = true;
				this.api.category.archive(this.id, () => {
					this.disabled = false;
					this.app.notifications.showSuccess('Subsystem deleted.');
					this.modal.close('deleted');
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}
		});
	}

	unarchiveCategory() {
		if (!this.canUnarchive) return;

		this.disabled = true;
		this.api.category.unarchive(this.id, () => {
			this.disabled = false;
			this.app.notifications.showSuccess('Subsystem restored.');
			this.modal.close('restored');
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	save() {
		this.disabled = true;
		this.api.category.save(this.data, savedId => {
			this.disabled = false;
			this.modal.close(savedId);
			if (this.data.record.id === 'new') {
				this.app.notifications.showSuccess('Subsystem created.');
			} else {
				this.app.notifications.showSuccess('Subsystem updated.');
			}
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	selectTab(id) {
		this.selectedTab = id;
	}

	addItem(item) {
		let i;

		for (i = 0; i < this.data.toolkit.length; i++) {
			if (this.data.toolkit[i].id === item.id) {
				this.app.notifications.showDanger('Product is already in the toolkit.');
			}
		}

		item.catalogue_linked = true;
		this.data.toolkit.push(item);
		this.data.toolkit = this.data.toolkit.slice();

		i = this.data.toolkit_remove.indexOf(item.id);
		if (i !== -1) this.data.toolkit_remove.splice(i, 1);
		this.data.toolkit_add.push(item.id);
	}

	removeItem(item) {
		let i = this.data.toolkit.indexOf(item);

		if (i !== -1) {
			this.data.toolkit.splice(i, 1);
			this.data.toolkit = this.data.toolkit.slice();

			i = this.data.toolkit_add.indexOf(item.id);
			if (i !== -1) this.data.toolkit_add.splice(i, 1);
			this.data.toolkit_remove.push(item.id);
		}
	}

	toolkitDrop(event) {
		// Update data model
		const previousIndex = event.previousIndex;
		const currentIndex = event.currentIndex;

		if (previousIndex === currentIndex) return; // No change

		const item = this.data.toolkit.splice(previousIndex, 1)[0];
		this.data.toolkit.splice(currentIndex, 0, item);
	}

	addProduct() {
		this.productSelectModal.open({
			sold_to_customer: 1,
			multi_select: true
		});
	}

	productSelectModalClosed(data) {
		if (data) {
			if (Mangler.isArray(data)) {
				data.forEach(item => {
					this.addItem(item);
				});
			} else {
				this.addItem(data);
			}
		}
	}

	editContentModalClosed(data) {
		if (data) {
			this.data.record.heading_content = data.content;
		}
	}

	addCategoryGroup() {
		this.editCategoryGroupModal.open({ id: 'new' });
	}

	editCategoryGroupModalClosed(data) {
		if (data) {
			this.refreshDropDowns(() => {
				this.data.record.category_group_id = data;
			});
		}
	}

	editProduct(product) {
		this.editedProduct = product;
		this.editProductModal.open({ id: product.id, no_clone: true });
	}

	editProductModalClosed(data) {
		if (data && data.id && this.editedProduct) {
			['image_url', 'manufacturer_name', 'short_description', 'sku'].forEach(f => { this.editedProduct[f] = data[f]; });
		}
	}

	removeImage(attr) {
		this.data.record[attr + '_id'] = null;
		this.data.record[attr + '_url'] = null;
	}

	openMediaLibrary(attr) {
		const sub = this.app.onMediaLibrarySelect.subscribe(data => {
			sub.unsubscribe();
			if (data) {
				this.data.record[attr + '_id'] = data.id;
				this.data.record[attr + '_url'] = data.url;
			}
		});

		this.app.mediaLibrary.open({ type: 'asset' });
	}

	restoreImage(attr) {
		this.data.record[attr + '_id'] = this.data.original[attr + '_id'];
		this.data.record[attr + '_url'] = this.data.original[attr + '_url'];
	}

	isCustomised(cName) {
		if (!this.data.record.heading_customisable) return false;
		return !!(this.data.record.heading_customisable[cName]);
	}

	getCustomisedUrl(cName, defaultUrl) {
		if (!this.data.record.heading_customisable) return defaultUrl;
		return this.data.record.heading_customisable[cName] || defaultUrl;
	}

	removeCustomImage(cName) {
		if (!this.data.record.heading_customisable) return;
		delete this.data.record.heading_customisable[cName];
	}

	findHeader(headerId) {
		let found = null;
		this.headerList.forEach(h => {
			if (h.id === headerId) found = h;
		});
		return found;
	}

	getCustomisedValue(cName) {
		if (!this.data.record.heading_customisable) return '';
		return this.data.record.heading_customisable[cName] || '';
	}

	setCustomisedValue(cName, value) {
		if (!this.data.record.heading_customisable) this.data.record.heading_customisable = {}; // Initialize if it doesn't exist

		if (value !== '') {
			this.data.record.heading_customisable[cName] = value;
		} else {
			delete this.data.record.heading_customisable[cName];
		}
	}

	openMediaLibraryCustomisable(cName) {
		const sub = this.app.onMediaLibrarySelect.subscribe(data => {
			sub.unsubscribe();
			if (data) {
				if (!this.data.record.heading_customisable) this.data.record.heading_customisable = {};
				this.data.record.heading_customisable[cName] = data.url;
			}
		});

		this.app.mediaLibrary.open({ type: 'asset' });
	}

}
