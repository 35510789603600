import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { ModalCloneProductComponent } from 'app/modal/modal-clone-product/modal-clone-product.component';
import { ModalEditBundleCounterComponent } from 'app/modal/modal-edit-bundle-counter/modal-edit-bundle-counter.component';
import { ModalEditBundleQuestionComponent } from 'app/modal/modal-edit-bundle-question/modal-edit-bundle-question.component';
import { ModalEditEntityComponent } from 'app/modal/modal-edit-entity/modal-edit-entity.component';
import { ModalEditLabourComponent } from 'app/modal/modal-edit-labour/modal-edit-labour.component';
import { ModalEditPricingStructureComponent } from 'app/modal/modal-edit-pricing-structure/modal-edit-pricing-structure.component';
import { ModalEditProductTypeComponent } from 'app/modal/modal-edit-product-type/modal-edit-product-type.component';
import { ModalSelectEntityComponent } from 'app/modal/modal-select-entity/modal-select-entity.component';
import { ModalSelectProductComponent } from 'app/modal/modal-select-product/modal-select-product.component';
import { BundleOptions } from 'app/shared/bundle-options';
import { DecimalPipe } from 'app/shared/decimal.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { ModalHelpFormulaComponent } from '../modal-help-formula/modal-help-formula.component';

declare const Mangler: any;

@Component({
	selector: 'modal-edit-product',
	templateUrl: './modal-edit-product.component.html',
	styleUrls: ['./modal-edit-product.component.scss']
})
export class ModalEditProductComponent extends UIModalBaseComponent {

	@ViewChild(ModalSelectProductComponent) productSelectModal: ModalSelectProductComponent;
	@ViewChild(ModalCloneProductComponent) productCloneModal: ModalCloneProductComponent;
	@ViewChild(ModalSelectEntityComponent) productEntitySelectModal: ModalSelectEntityComponent;
	@ViewChild(ModalEditBundleCounterComponent) productBundleCounterEditModal: ModalEditBundleCounterComponent;
	@ViewChild(ModalEditBundleQuestionComponent) productBundleQuestionEditModal: ModalEditBundleQuestionComponent;
	@ViewChild(ModalEditEntityComponent) editEntityModal: ModalEditEntityComponent;
	@ViewChild(ModalEditProductTypeComponent) editProductTypeModal: ModalEditProductTypeComponent;
	@ViewChild(ModalEditLabourComponent) editLabourModal: ModalEditLabourComponent;
	@ViewChild(ModalEditPricingStructureComponent) editPricingStructureModal: ModalEditPricingStructureComponent;
	@ViewChild(ModalHelpFormulaComponent) formulaHelpModal: ModalHelpFormulaComponent;

	id;
	details;
	list;
	usedBy;
	barcodeURL;
	organisationPricing;
	bundle: BundleOptions = null;
	hoveredQuestion = null;
	disabled = false;
	editable = false;
	selectedProductPrice = null;

	highlightedBom = null;

	labourIndex = {};
	subscriptionIndex = {};
	pricingStructureIndex = {};

	multiCurrency = false;

	usedByTotal = 0;

	manufacturerSuppliers = [];

	quoteId = null;
	quoteLabour = null;
	quoteAccessories = null;

	prefill = null;
	noClone = false;

	labourFingerprintBefore = '';

	private productSelectModalCaller;
	private newLabourIndex;

	tabs: any = {
		details: { id: 'details', title: 'Product details' },
		additional: { id: 'additional', title: 'Additional Options' },
		bom: { id: 'bom', title: 'Bill of materials', badgeClass: 'badge-success' },
		bundle: { id: 'bundle', title: 'Bundle options', badgeClass: 'badge-success' },
		accessories: { id: 'accessories', title: 'Accessories', badgeClass: 'badge-success' },
		used: { id: 'used', title: 'Used by', badgeClass: 'badge-info' }
	};

	tabList = [];
	activeTab = 'details';

	get hasBOM() { return !!this.details.has_bom; }
	set hasBOM(value) {
		this.details.has_bom = value ? 1 : 0;
		if (value) {
			this.isBundle = false;
			this.isStocked = true;
			this.recalculatePricing();
		}
		this.refreshTabs();
	}

	get isBundle() { return !!this.details.is_bundle; }
	set isBundle(value) {
		this.details.is_bundle = value ? 1 : 0;
		if (value) {
			this.hasBOM = false;
			this.canSplit = false;
			this.isStocked = true;
			this.recalculatePricing();
		}
		this.refreshTabs();
	}

	get isStocked() { return !!this.details.is_stocked; }
	set isStocked(value) {
		this.details.is_stocked = value ? 1 : 0;
		if (!value) this.hasBOM = false;
		this.recalculatePricing();
		this.refreshTabs();
	}

	get ignorePriceWarnings() { return !!this.details.ignore_price_warnings; }
	set ignorePriceWarnings(value) {
		this.details.ignore_price_warnings = value ? 1 : 0;
	}

	get specialOrder() { return !!this.details.special_order; }
	set specialOrder(value) {
		this.details.special_order = value ? 1 : 0;
	}

	get discontinued() { return !!this.details.discontinued; }
	set discontinued(value) {
		this.details.discontinued = value ? 1 : 0;
		this.refreshTabs();
	}

	get canChangeUnit() { return this.details && this.usedBy.bom.length === 0 && this.editable; }
	get canChangeAssembly() { return this.details && (!this.hasBOM || !this.details.bom.length) && this.editable; }
	get canChangeStocked() { return this.details && !this.hasBOM && this.editable; }

	get soldToCustomer() { return !!this.details.sold_to_customer; }
	set soldToCustomer(value) { this.details.sold_to_customer = value ? 1 : 0; }

	get canSplit() { return !!this.details.can_split; }
	set canSplit(value) { this.details.can_split = value ? 1 : 0; }

	get hideAccessories() { return !!this.details.hide_accessories; }
	set hideAccessories(value) { this.details.hide_accessories = value ? 1 : 0; }

	get overrideHideAccessories() { return !!this.details.override.hide_accessories; }
	set overrideHideAccessories(value) { this.details.override.hide_accessories = value ? 1 : 0; }

	get fixedImage() { return !!this.details.fixed_image; }
	set fixedImage(value) { this.details.fixed_image = value ? 1 : 0; }

	constructor(
		public app: AppService,
		private api: ApiService,
	) {
		super();
	}

	open(mdata: any) {
		this.quoteLabour = mdata.quote_labour?.length ? mdata.quote_labour : null;
		this.quoteAccessories = mdata.quote_accessories?.length ? mdata.quote_accessories : null;
		this.quoteId = mdata.quote_id || null;
		this.prefill = mdata.prefill || null;
		this.noClone = mdata.no_clone;

		this.openProduct(mdata.id || 'new');
	}

	openProduct(id, pop = true) {
		this.id = id;

		this.details = null;
		this.list = null;
		this.usedBy = null;
		this.usedByTotal = 0;

		const success = data => {
			this.details = data.details || {};
			this.list = data.list;
			this.usedBy = data.used_by;
			this.barcodeURL = data.barcode_url;
			this.organisationPricing = data.organisation_pricing;
			this.editable = data.editable;
			this.selectedProductPrice = this.details.product_price[0];

			// See if we need to enable multi-currency features for this form
			this.multiCurrency = this.list.currencies.length > 1 || this.details.product_price[0].currency_id !== this.list.currencies[0].currency_id;

			// Create bundle object
			if (this.details.bundle) {
				this.bundle = new BundleOptions(this.details.bundle);
				delete this.details.bundle;
			} else {
				this.bundle = null;
			}

			this.labourIndex = Mangler.index(this.list.labour_types, 'id');
			this.subscriptionIndex = Mangler.index(this.list.subscription_types, 'id');
			this.pricingStructureIndex = Mangler.index(this.list.pricing_structures, 'id');

			let cIndex;

			// Put labour types into categories
			this.list.labour_categories.forEach(c => c.types = []);
			cIndex = Mangler.index(this.list.labour_categories, 'id');
			this.list.labour_types.forEach(t => { cIndex[t.category_id]?.types.push(t); });

			// Put subscription types into categories
			this.list.subscription_categories.forEach(c => c.types = []);
			cIndex = Mangler.index(this.list.subscription_categories, 'id');
			this.list.subscription_types.forEach(t => { cIndex[t.category_id]?.types.push(t); });

			// Put counters into counter groups
			this.list.counter_groups.forEach(c => c.items = []);
			cIndex = Mangler.index(this.list.counter_groups, 'id');
			this.list.counters.forEach(t => { cIndex[t.group_id]?.items.push(t); });

			this.usedByTotal = 0;
			Mangler.each(this.usedBy, (k, v) => {
				if (Mangler.isArray(v)) this.usedByTotal += v.length;
			});

			// Make supplier flags boolean
			this.details.suppliers.forEach(s => { s.is_primary = !!s.is_primary; });

			// Check if we need to prefill fields
			if (this.id === 'new' && this.prefill) {
				if (this.prefill.currency_id) this.details.product_price[0].currency_id = this.prefill.currency_id;
				if (this.prefill.base_unit_price) this.details.product_price[0].sell_price = this.prefill.base_unit_price;
				if (this.prefill.base_unit_cost) this.details.product_price[0].cost_price = this.prefill.base_unit_cost;
				if (this.prefill.description) this.details.short_description = this.prefill.description;
				if (this.prefill.subtitle) this.details.model = this.prefill.subtitle;
				if (this.prefill.image_id && this.prefill.image_url) {
					this.details.image_id = this.prefill.image_id;
					this.details.image_url = this.prefill.image_url;
				}
				if (this.prefill.labour?.length) {
					this.prefill.labour.forEach(item => {
						this.details.labour.push({
							id: 'new',
							labour_type_id: item.labour_type_id,
							labour_hours: item.labour_hours
						});
					});
				}
				if (this.prefill.subscription?.length) {
					this.prefill.subscription.forEach(item => {
						this.details.subscription.push({
							id: 'new',
							subscription_type_id: item.subscription_type_id,
							quantity: item.quantity
						});
					});
				}
			}

			this.labourFingerprintBefore = this.getLabourFingerprint();

			this.refreshSelections();
			this.formatNumbers();

			this.tabList = [];

			this.refreshTabs();
			this.activeTab = 'details';

			this.refreshManufacturerSuppliers();

			// Finally, copy quote line items if prefilled
			if (this.id === 'new' && this.prefill) {
				this.copyQuoteLabour();
				this.copyQuoteAccessories();
			}

			if (pop) this.modal.open();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
			if (!pop) this.modal.close('clone_error');
		};

		if (this.id === 'new') {
			this.api.product.new({ quote_id: this.quoteId }, success, fail);
		} else {
			this.api.product.get(this.id, success, fail);
		}
	}

	refreshDropDowns(callback) {
		const success = data => {
			this.list = data.list;

			this.labourIndex = Mangler.index(this.list.labour_types, 'id');
			this.subscriptionIndex = Mangler.index(this.list.subscription_types, 'id');
			this.pricingStructureIndex = Mangler.index(this.list.pricing_structures, 'id');

			let cIndex;

			// Put labour types into categories
			this.list.labour_categories.forEach(c => c.types = []);
			cIndex = Mangler.index(this.list.labour_categories, 'id');
			this.list.labour_types.forEach(t => { cIndex[t.category_id]?.types.push(t); });

			// Put subscription types into categories
			this.list.subscription_categories.forEach(c => c.types = []);
			cIndex = Mangler.index(this.list.subscription_categories, 'id');
			this.list.subscription_types.forEach(t => { cIndex[t.category_id]?.types.push(t); });

			this.refreshSelections();
			callback?.();
		};

		const fail = error => {
			this.app.notifications.showDanger(error.message);
			callback?.();
		};

		if (this.id === 'new') {
			this.api.product.new({ quote_id: this.quoteId }, success, fail);
		} else {
			this.api.product.get(this.id, success, fail);
		}
	}

	refreshTabs() {
		// Add enabled tabs from pre-generated tab instance list

		this.tabList = [];
		this.tabList.push(this.tabs.details);
		this.tabList.push(this.tabs.additional);
		if (this.hasBOM) this.tabList.push(this.tabs.bom);
		if (this.isBundle && this.app.orgInfo.role.manage_products) this.tabList.push(this.tabs.bundle);
		this.tabList.push(this.tabs.accessories);
		if (this.usedByTotal) this.tabList.push(this.tabs.used);

		// Refresh tab badges

		this.tabs.used.badge = this.usedByTotal ? '' + this.usedByTotal : '';
		this.tabs.used.badgeClass = this.discontinued ? 'badge-danger' : 'badge-info';
		this.tabs.bom.badge = this.details.bom.length ? '' + this.details.bom.length : '';
		this.tabs.accessories.badge = this.details.accessories.length ? '' + this.details.accessories.length : '';
	}

	refreshSelections() {
		this.recalculatePricing();
	}

	formatNumbers(addThousandSeparators: boolean = true) {
		this.details.width = parseInt(this.details.width, 10) || 0;
		this.details.height = parseInt(this.details.height, 10) || 0;
		this.details.depth = parseInt(this.details.depth, 10) || 0;
		this.details.split_pack_quantity = DecimalPipe.transform(this.details.split_pack_quantity, 0, 4, addThousandSeparators);

		this.details.product_price.forEach(item => {
			item.cost_price = DecimalPipe.transform(item.cost_price, 2, 4, addThousandSeparators);
			item.sell_price = DecimalPipe.transform(item.sell_price, 2, 4, addThousandSeparators);
			item.srp_price = DecimalPipe.transform(item.srp_price, 2, 4, addThousandSeparators);
		});

		this.details.bom.forEach(item => {
			item.quantity = DecimalPipe.transform(item.quantity, 0, 4, addThousandSeparators);
		});

		this.details.accessories.forEach(item => {
			item.default_quantity = DecimalPipe.transform(item.default_quantity, 0, 4, addThousandSeparators);
		});

		this.details.labour.forEach(item => {
			item.labour_hours = DecimalPipe.transform(item.labour_hours, 2, 4, addThousandSeparators);

			const labourType = this.labourIndex[item.labour_type_id];
			if (labourType) {
				item.hours_per_day = labourType.hours_per_day;
			} else {
				item.hours_per_day = 8;
			}

			item.labour_days = item.hours_per_day < 0.01 ? 0 : DecimalPipe.parse(item.labour_hours) / item.hours_per_day;
			item.labour_days = DecimalPipe.transform(item.labour_days, 2, 2, addThousandSeparators);
		});

		this.details.subscription.forEach(item => {
			item.quantity = DecimalPipe.transform(item.quantity, 2, 2, addThousandSeparators);
		});

		this.details.counters.forEach(item => {
			item.amount = DecimalPipe.transform(item.amount, 0, 2, addThousandSeparators);
		});

		this.recalculatePricing('', addThousandSeparators);
	}

	labourHoursChanged(item) {
		if (item.hours_per_day < 0.01) {
			item.labour_days = 0;
		} else {
			item.labour_days = DecimalPipe.parse(item.labour_hours) / item.hours_per_day;
		}

		item.labour_days = DecimalPipe.transform(item.labour_days, 2, 2, true);
	}

	labourDaysChanged(item) {
		item.labour_hours = DecimalPipe.parse(item.labour_days) * item.hours_per_day;
		item.labour_hours = DecimalPipe.transform(item.labour_hours, 2, 4, true);
	}

	recalculatePricing(dontReformat = '', addThousandSeparators: boolean = true) {
		// Calculate BOM costs
		this.details.bom.forEach(item => {
			const unit = Mangler.findOne(item.info.units, { id: item.unit_id });
			item.cost = 0;
			item.sell = 0;
			if (unit) {
				item.cost = DecimalPipe.parse(item.info.cost_price) * DecimalPipe.parse(item.quantity) * DecimalPipe.parse(unit.units);
				item.sell = DecimalPipe.parse(item.info.sell_price) * DecimalPipe.parse(item.quantity) * DecimalPipe.parse(unit.units);
			}
		});

		// Product pricing
		this.details.product_price.forEach(pp => {
			// Calculate product cost
			let cost = DecimalPipe.parse(pp.cost_price);

			// Recalculate cost from BOM
			// TODO: Multi-currency BOM support
			if (this.hasBOM) {
				cost = 0;
				this.details.bom.forEach(item => cost += item.cost);
			}

			// Calculate prices
			let ps = null;
			if (pp.pricing_structure_id) {
				ps = this.pricingStructureIndex[pp.pricing_structure_id] || null;
			}

			const method = ps?.pricing_method || 'custom';
			const value = DecimalPipe.parse(ps?.pricing_value);
			const decimalPlaces = DecimalPipe.parse(ps?.decimal_places);
			const minimum = DecimalPipe.parse(ps?.minimum_price);

			let basePrice = cost;
			if (ps?.base_price === 'srp_price') basePrice = DecimalPipe.parse(pp.srp_price);

			let price = 0;

			switch (method) {
				case 'custom':
					// Leave it as-is
					break;

				case 'bom':
					// TODO: Multi-currency BOM support
					if (this.hasBOM) {
						this.details.bom.forEach(item => {
							const unit = Mangler.findOne(item.info.units, { id: item.unit_id });
							if (unit) price += DecimalPipe.parse(item.info.sell_price) * DecimalPipe.parse(item.quantity) * DecimalPipe.parse(unit.units);
						});
					}
					break;

				case 'markup':
					price = basePrice * (1 + value / 100);
					break;

				case 'margin':
					if (value >= 100) {
						price = 0;
					} else {
						price = basePrice / (1 - (value / 100));
					}
					break;

				case 'profit':
					price = basePrice + value;
					break;
			}

			if (method !== 'custom') {
				price *= Math.pow(10, decimalPlaces);
				price = Math.round(price);
				price /= Math.pow(10, decimalPlaces);

				if (method !== 'custom' && method !== 'bom') price = Math.max(price, minimum);

				pp.sell_price = DecimalPipe.transform(price, 2, 4, addThousandSeparators);
			}

			if (dontReformat !== 'cost') pp.cost_price = DecimalPipe.transform(cost, 2, 4, addThousandSeparators);
		});
	}

	getLabourCurrency(labour) {
		const labourType = this.labourIndex[labour.labour_type_id];
		if (!labourType) return '';
		return labourType.currency_id;
	}

	getLabourCost(labour) {
		const labourType = this.labourIndex[labour.labour_type_id];
		if (!labourType) return 0;

		const hours = DecimalPipe.parse(labour.labour_hours);
		const cost = DecimalPipe.parse(labourType.hourly_cost);
		return hours * cost;
	}

	getLabourPrice(labour) {
		const labourType = this.labourIndex[labour.labour_type_id];
		if (!labourType) return 0;

		const hours = DecimalPipe.parse(labour.labour_hours);
		const price = DecimalPipe.parse(labourType.hourly_price);
		return hours * price;
	}

	addLabour() {
		this.details.labour.push({
			id: 'new',
			labour_type_id: null,
			labour_hours: 1
		});
		this.details.labour = this.details.labour.slice();
		this.formatNumbers();
	}

	deleteLabour(labour) {
		const i = this.details.labour.indexOf(labour);

		if (i !== -1) {
			this.details.labour.splice(i, 1);
			this.details.labour = this.details.labour.slice();

			if (labour.id !== 'new') {
				if (!Mangler.isArray(this.details.labour_deleted)) this.details.labour_deleted = [];
				this.details.labour_deleted.push(labour);
			}
		}
	}

	getSubscriptionCurrency(subscription) {
		const subscriptionType = this.subscriptionIndex[subscription.subscription_type_id];
		if (!subscriptionType) return '';
		return subscriptionType.currency_id;
	}

	getSubscriptionCost(subscription) {
		const subscriptionType = this.subscriptionIndex[subscription.subscription_type_id];
		if (!subscriptionType) return 0;

		const quantity = DecimalPipe.parse(subscription.quantity);
		const cost = DecimalPipe.parse(subscriptionType.unit_cost);
		return quantity * cost;
	}

	getSubscriptionPrice(subscription) {
		const subscriptionType = this.subscriptionIndex[subscription.subscription_type_id];
		if (!subscriptionType) return 0;

		const quantity = DecimalPipe.parse(subscription.quantity);
		const price = DecimalPipe.parse(subscriptionType.retail_price);
		return quantity * price;
	}

	addSubscription() {
		this.details.subscription.push({
			id: 'new',
			subscription_type_id: null,
			quantity: 1
		});
		this.details.subscription = this.details.subscription.slice();
		this.formatNumbers();
	}

	deleteSubscription(subscription) {
		const i = this.details.subscription.indexOf(subscription);

		if (i !== -1) {
			this.details.subscription.splice(i, 1);
			this.details.subscription = this.details.subscription.slice();

			if (subscription.id !== 'new') {
				if (!Mangler.isArray(this.details.subscription_deleted)) this.details.subscription_deleted = [];
				this.details.subscription_deleted.push(subscription);
			}
		}
	}

	addCounter() {
		this.details.counters.push({
			counter_id: null,
			amount: 1
		});
		this.details.counters = this.details.counters.slice();
		this.formatNumbers();
	}

	deleteCounter(item) {
		const i = this.details.counters.indexOf(item);

		if (i !== -1) {
			this.details.counters.splice(i, 1);
			this.details.counters = this.details.counters.slice();
		}
	}

	addFactor() {
		this.details.factors.push({
			condition: null,
			a: '',
			b: '',
			cost: '',
			sell: ''
		});
		this.details.factors = this.details.factors.slice();
		this.formatNumbers();
	}

	deleteFactor(item) {
		const i = this.details.factors.indexOf(item);

		if (i !== -1) {
			this.details.factors.splice(i, 1);
			this.details.factors = this.details.factors.slice();
		}
	}

	factorDrop(event) {
		// Update data model
		const previousIndex = event.previousIndex;
		const currentIndex = event.currentIndex;

		if (previousIndex === currentIndex) return; // No change

		const item = this.details.factors.splice(previousIndex, 1)[0];
		this.details.factors.splice(currentIndex, 0, item);
	}

	addDocument() {
		this.details.documents.push({
			id: 'new',
			name: '',
			url: '',
			editable: true
		});
		this.details.documents = this.details.documents.slice();
	}

	deleteDocument(doc) {
		if (!doc.editable) return;

		const i = this.details.documents.indexOf(doc);

		if (i !== -1) {
			this.details.documents.splice(i, 1);
			this.details.documents = this.details.documents.slice();
		}
	}

	documentUrlChanged(doc) {
		doc.url = ('' + doc.url).trim();

		if (doc.url) {
			// Make sure URL starts with the protocol
			if (!doc.url.startsWith('http://') && !doc.url.startsWith('https://') && !doc.url.startsWith('ftp://')) {
				doc.url = 'https://' + doc.url;
			}
		}
	}

	addBomProduct() {
		this.productSelectModalCaller = 'addBomProduct';
		this.productSelectModal.open({});
	}

	deleteBomProduct(item) {
		const i = this.details.bom.indexOf(item);
		if (i !== -1) {
			const removedItem = this.details.bom.splice(i, 1)[0];
			if (removedItem && removedItem.id !== 'new') {
				if (!this.details.bom_deleted) this.details.bom_deleted = [];
				this.details.bom_deleted.push(removedItem);
			}
			this.details.bom = this.details.bom.slice();
			this.formatNumbers();
			this.refreshTabs();
		}
	}

	addAccessories() {
		this.productSelectModalCaller = 'addAccessories';
		this.productSelectModal.open({
			is_bundle: 0,
			multi_select: true
		});
	}

	deleteAccessory(item) {
		const i = this.details.accessories.indexOf(item);
		if (i !== -1) {
			this.details.accessories.splice(i, 1);
			this.details.accessories = this.details.accessories.slice();
			this.refreshTabs();
		}
	}

	getImageFromProduct() {
		this.productSelectModalCaller = 'getImageFromProduct';
		this.productSelectModal.open({});
	}

	save() {
		this.formatNumbers(false);
		const productDetails = Mangler.clone(this.details);
		productDetails.bundle = this.bundle?.getBundleData();
		this.formatNumbers();

		this.disabled = true;
		this.api.product.save(productDetails, savedId => {
			this.disabled = false;
			if (this.details.id === 'new') {
				this.app.notifications.showSuccess('Product created.');
			} else {
				this.app.notifications.showSuccess('Product updated.');
			}
			productDetails.id = savedId;
			productDetails.labour_changed = this.getLabourFingerprint() !== this.labourFingerprintBefore;

			productDetails.manufacturer_name = '';
			if (productDetails.manufacturer_id) {
				const m = Mangler.findOne(this.list.manufacturers, { id: productDetails.manufacturer_id });
				if (m) productDetails.manufacturer_name = m.name;
			}

			this.modal.close(productDetails);
		}, error => {
			this.disabled = false;
			this.app.notifications.showDanger(error.message);
		});
	}

	removeImage() {
		if (this.editable) {
			this.details.image_id = null;
			this.details.image_url = null;
			if (this.app.orgInfo?.role.is_elevated) this.fixedImage = false;
		} else if (this.details?.override) {
			this.details.override.image_id = null;
			this.details.override.image_url = null;
		}
	}

	cloneProduct() {
		if (this.id === 'new') return;

		const cloneOptions = {
			accessories: this.details.accessories.length,
			bom: this.hasBOM && this.details.bom.length,
			labour: !!this.details.labour.length,
			subscription: !!this.details.subscription.length,
			suppliers: !!this.details.suppliers.length,
			warehouses: !!this.details.warehouses.length,
			documents: !!this.details.documents.length,
			counters: !!this.details.counters.length,
			bundle: this.isBundle
		};

		this.productCloneModal.open({
			id: this.id,
			sku: this.details.sku || '',
			model: this.details.model || '',
			short_description: this.details.short_description || '',
			long_description: this.details.long_description || '',
			clone: cloneOptions,
			allowed: Mangler.clone(cloneOptions)
		});
	}

	barcodeImageURL(code) {
		return this.barcodeURL + encodeURIComponent(code);
	}

	addSupplier(record) {
		this.details.suppliers.push({
			id: record.id,
			name: record.name,
			posttown: record.posttown,
			postcode: record.postcode,
			is_primary: !this.details.suppliers.length
		});

		this.details.suppliers = this.details.suppliers.slice();
	}

	removeSupplier(id) {
		const item = Mangler.findOne(this.details.suppliers, { id });

		if (item) {
			const i = this.details.suppliers.indexOf(item);

			if (i !== -1) {
				this.details.suppliers.splice(i, 1);
				this.details.suppliers = this.details.suppliers.slice();

				if (this.details.suppliers.length && !Mangler.findOne(this.details.suppliers, { is_primary: true })) {
					this.setPrimarySupplier(this.details.suppliers[0].id);
				}
			}
		}
	}

	modalAddSupplier() {
		this.productEntitySelectModal.open({
			filters: { is_supplier: true }
		});
	}

	setPrimarySupplier(id) {
		this.details.suppliers.forEach(s => {
			s.is_primary = (s.id === id);
		});
	}

	refreshManufacturerSuppliers(override = false) {
		this.manufacturerSuppliers = [];
		if (!this.editable) return;

		if (this.details?.manufacturer_id) {
			this.api.entity.details(this.details.manufacturer_id, data => {
				if (this.details?.manufacturer_id === data.details.id) {
					this.manufacturerSuppliers = data.details.suppliers || [];
					if (override) this.addManufacturerSuppliers();
				}
			}, () => {
				this.manufacturerSuppliers = [];
			});
		} else {
			if (override) this.addManufacturerSuppliers();
		}
	}

	addManufacturerSuppliers(hardReset = false) {
		if (hardReset) {
			if (!confirm('Are you sure you want to reset all suppliers for this product?')) return;

			// Clear all current suppliers
			this.details.suppliers = [];
		} else {
			// Only leave suppliers with customised SKU
			const customised = [];
			this.details.suppliers.forEach(s => {
				s.is_primary = false;
				if (s.sku) customised.push(s);
			});
			this.details.suppliers = customised;
		}

		// Add manufacturer's suppliers
		this.manufacturerSuppliers.forEach(s => {
			let item = Mangler.findOne(this.details.suppliers, { id: s.id });

			if (item) {
				// Already in the list
				if (s.is_primary) item.is_primary = true;
			} else {
				// Add
				item = Mangler.clone(s);
				item.sku = null;
				this.details.suppliers.push(item);
			}
		});
	}

	bundleAddBaseProduct() {
		this.productSelectModalCaller = 'bundleAddBaseProduct';
		this.productSelectModal.open({
			is_bundle: 0,
			multi_select: true
		});
	}

	bundleEditCounter(counter = null) {
		this.productBundleCounterEditModal.open({
			bundle: this.bundle,
			counter: counter ? Mangler.clone(counter) : this.bundle.getNewCounterData()
		});
	}

	bundleEditQuestion(parent = null, question = null) {
		// Remove recursive structure for cloning
		this.bundle.questions.forEach(q => delete q.children);

		this.productBundleQuestionEditModal.open({
			bundle: this.bundle,
			question: question ? Mangler.clone(question) : this.bundle.getNewQuestionData(parent)
		});

		// Restore structure
		this.bundle.refreshStructure();
	}

	bundleQuestionDefaultValue(q) {
		switch (q.type) {
			case 'numeric':
				return q.default_value;

			case 'checkbox':
				return q.default_value ? 'checked' : 'unchecked';

			case 'select':
			case 'multi-select':
				const list = Mangler.find(q.select_options, { $where: o => !!(q.default_value & o.value) });
				const valueList = list.map(o => '' + o.description);
				return valueList.join(', ');

			case 'product-select':
				const item = Mangler.findOne(q.products, { product_id: q.default_value });
				return item ? item.short_description : '';
		}
	}

	bundleQuestionTypeName(type) {
		switch (type) {
			case 'numeric': return 'Numeric';
			case 'select': return 'Select';
			case 'multi-select': return 'Multi-select';
			case 'checkbox': return 'Checkbox';
			case 'product-select': return 'Product select';

			default: return '';
		}
	}

	bundleQuestionCondition(q) {
		if (!q.parent) return '';

		const type = q.parent.type;
		const mode = q.parent_mode;
		const value = q.parent_value || 0;
		const maxValue = q.parent_max_value || 0;
		const field = 'Parent';

		let valueDescription = '';
		let valueList = [];
		let list = [];

		// Types: numeric, select, multi-select, checkbox
		// Modes: set, value, range, lt, gt, all, any

		switch (type) {
			case 'numeric':
				switch (mode) {
					case 'set': return field + ' is not 0';
					case 'value': return field + ' = ' + value;
					case 'range': return field + ' between ' + value + ' and ' + maxValue;
					case 'lt': return field + ' < ' + value;
					case 'gt': return field + ' > ' + value;

					default: return '';
				}

			case 'select':
				list = Mangler.find(q.parent.select_options, { $where: o => !!(value & o.value) });
				valueList = list.map(o => '' + o.description);
				valueDescription = valueList.join(', ');

				switch (mode) {
					case 'set': return field + ' is set';
					case 'value': return field + ' is ' + valueDescription;
					case 'any': return field + ' is one of ' + valueDescription;

					default: return '';
				}

			case 'product-select':
				const item = Mangler.findOne(q.parent.products, { product_id: value });
				valueDescription = item ? item.short_description : '';

				switch (mode) {
					case 'set': return field + ' is set';
					case 'value': return field + ' is ' + valueDescription;

					default: return '';
				}

			case 'multi-select':
				list = Mangler.find(q.parent.select_options, { $where: o => !!(value & o.value) });
				valueList = list.map(o => '' + o.description);
				valueDescription = valueList.join(', ');

				switch (mode) {
					case 'set': return field + ' is set';
					case 'value': return field + ' is exactly ' + valueDescription;
					case 'any': return field + ' has any of ' + valueDescription;
					case 'all': return field + ' has all of ' + valueDescription;

					default: return '';
				}

			case 'checkbox':
				switch (mode) {
					case 'set': return field + ' is checked';
					case 'value': return field + ' is ' + (value ? 'not checked' : 'checked');

					default: return '';
				}

			default:
				return '';
		}
	}

	activeTabDescription() {
		const tab = Mangler.findOne(this.tabList, { id: this.activeTab });
		return tab ? tab.title : '';
	}

	productSelectModalClosed(data) {
		if (data) {
			let product, found;

			switch (this.productSelectModalCaller) {
				case 'addAccessories':
					data.forEach(p => {
						found = Mangler.findOne(this.details.accessories, { id: p.id });

						if (found) {
							this.app.notifications.showDanger('Product is already an accessory.');
							return;
						}

						if (p.id === this.details.id) {
							this.app.notifications.showDanger('Product cannot be its own accessory.');
							return;
						}

						const accessory = {
							id: p.id,
							sku: p.sku,
							manufacturer_name: p.manufacturer_name,
							manufacturer_sku: p.manufacturer_sku,
							model: p.model,
							short_description: p.short_description,
							image_url: p.image_url,
							catalogue_id: p.catalogue_id,
							catalogue_name: p.catalogue_name,
							catalogue_image_url: p.catalogue_image_url,
							default_quantity: 1
						};

						this.details.accessories.push(accessory);
					});

					this.details.accessories = this.details.accessories.slice();
					this.refreshTabs();
					break;

				case 'addBomProduct':
					product = data;

					if (product.id === this.details.id) {
						this.app.notifications.showDanger('Product cannot be put in its own bill of materials.');
						return;
					}

					this.disabled = true;
					this.api.product.bomProduct(this.id, product.id, bomItem => {
						this.disabled = false;
						this.highlightedBom = bomItem;
						this.details.bom.push(bomItem);
						this.details.bom = this.details.bom.slice();
						this.formatNumbers();
						this.refreshTabs();
					}, error => {
						this.disabled = false;
						this.app.notifications.showDanger(error.message);
					});
					break;

				case 'bundleAddBaseProduct':
					data.forEach(p => {
						found = Mangler.findOne(this.bundle.products, { product_id: p.id });

						if (found) return;
						if (p.id === this.details.id) return;

						this.bundle.addProduct({
							product_id: p.id,
							model: p.model,
							short_description: p.short_description,
							manufacturer_name: p.manufacturer_name,
							sku: p.sku,
							manufacturer_sku: p.manufacturer_sku,
							image_url: p.image_url,
							quantity: 1
						});
					});
					break;

				case 'getImageFromProduct':
					if (this.editable) {
						this.details.image_id = data.image_id;
						this.details.image_url = data.image_url;
						if (this.app.orgInfo?.role.is_elevated) this.fixedImage = true;
					} else if (this.details?.override) {
						this.details.override.image_id = data.image_id;
						this.details.override.image_url = data.image_url;
					}
					break;
			}
		}
	}

	productCloneModalClosed(data) {
		if (data) {
			this.formatNumbers(false);
			const productDetails = Mangler.clone(this.details);
			this.formatNumbers();

			this.disabled = true;
			this.api.product.save(productDetails, () => {
				this.api.product.clone(data, newProductId => {
					this.disabled = false;
					this.app.notifications.showSuccess('Product cloned.');
					this.openProduct(newProductId, false);
				}, error => {
					this.disabled = false;
					this.app.notifications.showDanger(error.message);
				});
			}, error => {
				this.disabled = false;
				this.app.notifications.showDanger(error.message);
			});
		}
	}

	productEntitySelectModalClosed(data) {
		if (data) {
			if (Mangler.findOne(this.details.suppliers, { id: data.id })) {
				this.app.notifications.showDanger('Supplier is already in the list.');
				return;
			}

			this.addSupplier(data);
		}
	}

	openMediaLibrary() {
		const sub = this.app.onMediaLibrarySelect.subscribe(data => {
			sub.unsubscribe();
			if (data) {
				if (this.editable) {
					this.details.image_id = data.id;
					this.details.image_url = data.url;
					if (this.app.orgInfo?.role.is_elevated) this.fixedImage = true;
				} else if (this.details?.override) {
					this.details.override.image_id = data.id;
					this.details.override.image_url = data.url;
				}
			}
		});

		this.app.mediaLibrary.open({ type: 'product' });
	}

	addNewManufacturer() {
		this.editEntityModal.open({ id: 'new', is_manufacturer: true });
	}

	editEntityModalClosed(data) {
		if (data && data !== 'deleted' && data !== 'restored') {
			this.disabled = true;
			this.refreshDropDowns(() => {
				this.disabled = false;
				this.details.manufacturer_id = data;
				this.refreshManufacturerSuppliers(true);
			});
		}
	}

	addNewPricingLevel() {
		this.editPricingStructureModal.open({ id: 'new' });
	}

	editPricingStructureModalClosed(data) {
		if (data && data !== 'deleted' && data !== 'restored') {
			this.disabled = true;
			this.refreshDropDowns(() => {
				this.disabled = false;
				if (this.selectedProductPrice) this.selectedProductPrice.pricing_structure_id = data;
				this.refreshSelections();
			});
		}
	}

	addNewProductType() {
		this.editProductTypeModal.open({ id: 'new' });
	}

	editProductTypeModalClosed(data) {
		if (data) {
			this.disabled = true;
			this.refreshDropDowns(() => {
				this.disabled = false;
				this.details.type_id = data;
			});
		}
	}

	addNewLabourType(index) {
		this.newLabourIndex = index;
		this.editLabourModal.open({ id: 'new' });
	}

	editLabourModalClosed(data) {
		if (data?.id) {
			this.disabled = true;
			this.refreshDropDowns(() => {
				this.disabled = false;
				this.details.labour[this.newLabourIndex].labour_type_id = data.id;
				this.refreshSelections();
			});
		}
	}

	filteredQuoteAccessories() {
		if (!this.quoteAccessories) return [];

		return this.quoteAccessories.filter(item => {
			// acc: id, default_quantity
			// qacc: product_id, quantity
			const found = Mangler.findOne(this.details.accessories, { id: item.product_id });
			if (found) {
				if (found.by_owner) return false;
				// eslint-disable-next-line eqeqeq
				if (item.quantity == found.default_quantity) return false;
			}
			return true;
		});
	}

	copyQuoteLabour() {
		if (!this.quoteLabour) return;

		this.details.labour.forEach(labour => {
			if (labour.id !== 'new') {
				if (!Mangler.isArray(this.details.labour_deleted)) this.details.labour_deleted = [];
				this.details.labour_deleted.push(labour);
			}
		});

		this.details.labour = [];

		this.quoteLabour.forEach(item => {
			this.details.labour.push({
				id: 'new',
				labour_type_id: item.labour_type_id,
				labour_hours: item.labour_hours
			});
		});
		this.details.labour = this.details.labour.slice();
		this.formatNumbers();
		this.quoteLabour = null;
	}

	copyQuoteAccessories() {
		if (!this.quoteAccessories) return;

		const list = this.filteredQuoteAccessories();

		list.forEach(item => {
			const found = Mangler.findOne(this.details.accessories, { id: item.product_id });
			if (found) {
				// Update quantity if needed
				// eslint-disable-next-line eqeqeq
				if (item.quantity != found.default_quantity) found.default_quantity = item.quantity;
			} else {
				// Add new accessory
				const accessory = {
					id: item.product_id,
					sku: item.sku,
					manufacturer_name: item.manufacturer_name,
					model: item.model,
					image_url: item.image_url,
					default_quantity: item.quantity
				};

				this.details.accessories.push(accessory);
			}
		});

		this.details.accessories = this.details.accessories.slice();
		this.refreshTabs();
		this.quoteAccessories = null;
	}

	hasOverride() {
		if (!this.details) return false;
		if (!this.details.override) return false;
		if (this.details.override.sku !== null) return true;
		if (this.details.override.model !== null) return true;
		if (this.details.override.short_description !== null) return true;
		if (this.details.override.long_description !== null) return true;
		if (this.details.override.image_id !== null) return true;
		if (this.details.override.hide_accessories !== null) return true;
		return false;
	}

	getLabourFingerprint() {
		if (!this.details) return '';

		const labourList = [];
		const labourIndex = {};

		// Aggregate labour hours by type
		this.details.labour.forEach(item => {
			const hrs = DecimalPipe.parse(item.labour_hours);
			if (item.labour_type_id && hrs > 0) {
				if (labourIndex[item.labour_type_id]) {
					labourIndex[item.labour_type_id].hrs += hrs;
				} else {
					const l = { id: item.labour_type_id, hrs };
					labourIndex[l.id] = l;
					labourList.push(l);
				}
			}
		});

		// Sort labour list
		labourList.sort((a, b) => {
			if (a.id < b.id) return -1;
			if (a.id > b.id) return 1;
			return 0;
		});

		// Generate fingerprint
		const fingerprint = labourList.map(item => item.id + '*' + DecimalPipe.transform(item.hrs, 2, 2, false));
		return fingerprint.join('|');
	}

	deleteProduct() {
		this.app.dialog.pop('Delete Product', 'Are you sure you want to delete this product? It will be marked as discontinued.', ['Cancel', '-Delete Product'], btn => {
			if (btn === 1) {
				this.discontinued = true;
				this.save();
			}
		});
	}

	currenciesNotAdded() {
		const list = this.list.currencies.map(item => item.currency_id);
		this.details.product_price.forEach(pp => {
			const i = list.indexOf(pp.currency_id);
			if (i !== -1) list.splice(i, 1);
		});
		return list;
	}

	isPrimaryProductPrice() {
		return this.details.product_price.indexOf(this.selectedProductPrice) === 0;
	}

	setPrimaryProductPrice() {
		if (!this.selectedProductPrice) return;

		const i = this.details.product_price.indexOf(this.selectedProductPrice);
		if (i !== -1) {
			this.details.product_price.splice(i, 1);
			this.details.product_price.unshift(this.selectedProductPrice);
		}
	}

	removeProductPrice() {
		if (this.isPrimaryProductPrice() || !this.selectedProductPrice) return;

		const currency = this.selectedProductPrice.currency_id;

		this.app.dialog.pop('Delete ' + currency + ' price', 'Are you sure you want to remove the ' + currency + ' price from this product?', ['Cancel', '-Delete Price'], button => {
			if (button === 1) {
				const i = this.details.product_price.indexOf(this.selectedProductPrice);
				if (i !== -1) {
					this.details.product_price.splice(i, 1);
				}

				this.selectedProductPrice = this.details.product_price[0];
			}
		});
	}

	addCurrency(currency) {
		const newPrice = {
			currency_id: currency,
			pricing_structure_id: null,
			cost_price: 0,
			sell_price: 0,
			srp_price: 0
		};

		this.details.product_price.push(newPrice);
		this.selectedProductPrice = newPrice;

		this.formatNumbers();
	}

	bundleRemoveProduct(item) {
		this.app.dialog.pop('Remove base product', 'Are you sure you want to remove base product "' + item.short_description + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) this.bundle.removeProduct(item);
		});
	}

	bundleRemoveCounter(item) {
		this.app.dialog.pop('Remove accumulator', 'Are you sure you want to remove accumulator "' + item.description + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) this.bundle.removeCounter(item);
		});
	}

	bundleRemoveQuestion(q) {
		this.app.dialog.pop('Remove question', 'Are you sure you want to remove question "' + q.question + '"?', ['Cancel', '*Delete'], btn => {
			if (btn === 1) this.bundle.removeQuestion(q);
		});
	}

}
