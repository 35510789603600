import { Injectable } from '@angular/core';
import { QuoteEditorComponent } from './quote-editor/quote-editor.component';
import { Util } from 'app/shared/util';

declare const Mangler: any;

@Injectable({
	providedIn: 'any'
})
export class QuoteService {

	public editor: QuoteEditorComponent = null;

	stageFilter = 'all';
	showArchived = false;
	assignedToFilter = null;
	zohoCRMSyncFilter = null;

	moduleMap = {
		quote: 'Quote',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'sales-order': 'Sales Order'
	};

	expandedCategoryGroups = [];
	expandedFloors = [];
	collapsedOptions = [];
	toolkitSearch = '';
	toolkitCollapsed = false;

	private _selectedStructure: any = null;
	private _selectedCategoryGroup: any = null;
	private _selectedCategory: any = null;
	private _selectedQuoteLine: any = null;

	get selectedStructure() { return this._selectedStructure; }
	set selectedStructure(value) {
		const oldId = this._selectedStructure ? this._selectedStructure.id : null;
		this._selectedStructure = value;
		if (value && oldId !== value.id) Util.scrollIntoView('scrollto-structure-' + value.id);
	}

	get selectedCategoryGroup() { return this._selectedCategoryGroup; }
	set selectedCategoryGroup(value) {
		const oldId = this._selectedCategoryGroup ? this._selectedCategoryGroup.id : null;
		const newId = value?.id || null;
		this._selectedCategory = null;
		this._selectedCategoryGroup = value;
		if (value && oldId !== value.id) Util.scrollIntoView('scrollto-category-group-' + value.id);
		if (oldId !== newId) this.toolkitSearch = '';
	}

	get selectedCategory() { return this._selectedCategory; }
	set selectedCategory(value) {
		const oldId = this._selectedCategory ? this._selectedCategory.id : null;
		const newId = value?.id || null;
		this._selectedCategoryGroup = null;
		this._selectedCategory = value;
		if (value && oldId !== value.id) Util.scrollIntoView('scrollto-category-' + value.id);
		if (oldId !== newId) this.toolkitSearch = '';
	}

	get selectedQuoteLine() { return this._selectedQuoteLine; }
	set selectedQuoteLine(value) {
		const oldId = this._selectedQuoteLine ? this._selectedQuoteLine.id : null;
		this._selectedQuoteLine = value;
		if (value && oldId !== value.id) Util.scrollIntoView('scrollto-product-' + value.id);
	}

	selectedOptionId;

	constructor() { }

	setCategoryGroupExpanded(id, state) {
		if (state) {
			if (!Mangler.first(this.expandedCategoryGroups, id)) this.expandedCategoryGroups.push(id);
		} else {
			Mangler.filter(this.expandedCategoryGroups, { $ne: id });
		}
		this.expandedCategoryGroups = this.expandedCategoryGroups.slice();
	}

	isCategoryGroupExpanded(id) {
		return !!Mangler.first(this.expandedCategoryGroups, id);
	}

	setFloorExpanded(id, state) {
		if (state) {
			if (!Mangler.first(this.expandedFloors, id)) this.expandedFloors.push(id);
		} else {
			Mangler.filter(this.expandedFloors, { $ne: id });
		}
		this.expandedFloors = this.expandedFloors.slice();
	}

	isFloorExpanded(id) {
		return !!Mangler.first(this.expandedFloors, id);
	}

	setOptionCollapsed(id, state) {
		if (state) {
			if (!Mangler.first(this.collapsedOptions, id)) this.collapsedOptions.push(id);
		} else {
			Mangler.filter(this.collapsedOptions, { $ne: id });
		}
		this.collapsedOptions = this.collapsedOptions.slice();
	}

	isOptionCollapsed(id) {
		return !!Mangler.first(this.collapsedOptions, id);
	}

}
