import { Component, Input } from '@angular/core';

@Component({
	selector: 'ui-wait',
	template:
		`<div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
			<div><ng-content></ng-content></div>
			<div *ngIf="spinner" class="spinner-border text-secondary"></div>
		</div>`
})
export class UIWaitComponent {

	@Input() spinner = true;

}
