import { Component, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { UIGridBaseComponent } from 'app/ui/ui-grid-base.component';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'app/app.service';
import { ApiService } from 'app/api.service';
import { ModalActivityLogComponent } from 'app/modal/modal-activity-log/modal-activity-log.component';
import { ModalProjectExportComponent } from 'app/modal/modal-project-exporter/modal-project-exporter.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-core-navigation',
	templateUrl: './core-navigation.component.html',
	styleUrls: ['./core-navigation.component.scss']
})
export class CoreNavigationComponent extends UIGridBaseComponent implements OnChanges, OnDestroy {

	@Input() org;

	@ViewChild(ModalActivityLogComponent) activityLogModal: ModalActivityLogComponent;
	@ViewChild(ModalProjectExportComponent) projectExportModal: ModalProjectExportComponent;

	navigation: any = null;
	hovered = null;

	quoteSubscription: Subscription;
	projectSubscription: Subscription;

	quoteNoteCount = 0;
	billableTaskCount = 0;
	projectNoteCount = 0;

	constructor(
		public app: AppService,
		private api: ApiService,
		public route: ActivatedRoute
	) {
		super();

		this.refreshQuoteNotes();
		this.refreshProjectNotes();

		this.app.quoteNavigationChange.subscribe(() => {
			this.quoteNoteCount = 0;
			this.refreshQuoteNotes();
		});

		this.app.projectNavigationChange.subscribe(() => {
			this.projectNoteCount = 0;
			this.refreshProjectNotes();
		});
	}

	ngOnDestroy(): void {
		if (this.quoteSubscription) this.quoteSubscription.unsubscribe();
		if (this.projectSubscription) this.projectSubscription.unsubscribe();
	}

	ngOnChanges() {
		this.refresh();
	}

	refresh() {
		if (this.app.org) {
			this.api.organisation.navigation(data => {
				this.navigation = data;
			}, () => {
				this.navigation = null;
			});
		} else {
			this.navigation = null;
		}
	}

	isModuleSelected(module) {
		return this.app.routeData.module === module;
	}

	isPathSelected(path) {
		return this.app.routeData.path === path || (!this.app.routeData.path && !path);
	}

	isSubpathSelected(path) {
		return this.app.routeData.subpath === path || (!this.app.routeData.subpath && !path);
	}

	getNavigation(category) {
		return (this.navigation ? this.navigation[category] : null) || [];
	}

	getNavigationTutorialStep(path) {
		if (path === 'catalogue') return 101;
		if (path === 'configure') return 201;
		if (path === 'system') return 206;
		if (path === 'configure/labour') return 301;
		if (path === 'quote') return 401;
		return null;
	}

	getQuoteTutorialStep(path) {
		if (path === 'summary') return 502;
		if (path === 'editor') return 601;
		if (path === 'adjustments') return 701;
		if (path === 'view-proposal') return 801;
		return null;
	}

	refreshQuoteNotes() {
		const quoteId = this.app.routeParams.quote;

		if (!quoteId) {
			this.quoteNoteCount = 0;
			return;
		}

		this.api.activity.count(quoteId, 'quote', n => {
			if (quoteId === this.app.routeParams.quote) this.quoteNoteCount = n || 0;
		});
	}

	refreshBillableTaskCount() {
		this.api.task.billableCount(n => {
			this.billableTaskCount = n || 0;
		});
	}

	openQuoteNotes() {
		if (!this.app.routeParams.quote) return;

		this.activityLogModal.open({
			id: this.app.routeParams.quote,
			type: 'quote'
		});
	}

	refreshProjectNotes() {
		const projectId = this.app.routeParams.project;

		if (!projectId) {
			this.projectNoteCount = 0;
			return;
		}

		this.api.activity.count(projectId, 'project', n => {
			if (projectId === this.app.routeParams.project) this.projectNoteCount = n || 0;
		});
	}

	openProjectNotes() {
		if (!this.app.routeParams.project) return;

		this.activityLogModal.open({
			id: this.app.routeParams.project,
			type: 'project'
		});
	}

	openProjectExporter() {
		if (!this.app.routeParams.project) return;

		this.projectExportModal.open({
			context: 'project',
			id: this.app.routeParams.project
		});
	}

}
