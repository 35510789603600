import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'app/api.service';
import { AppService } from 'app/app.service';
import { QuoteService } from 'app/quote/quote.service';
import { MySQLDateToISOPipe } from 'app/shared/mysql-date-to-iso.pipe';
import { UIModalBaseComponent } from 'app/ui/ui-modal-base.component';
import { CalendarModule } from 'primeng/calendar';

@Component({
	selector: 'modal-project-exporter',
	templateUrl: './modal-project-exporter.component.html'
})
export class ModalProjectExportComponent extends UIModalBaseComponent {

	@ViewChild(CalendarModule) calendar: CalendarModule;

	context = 'all';
	projectId;
	quoteId;

	data;
	selectedType = null;
	defaultDate = new Date();
	start = new Date();
	end = new Date();

	title = '';

	constructor(
		public app: AppService,
		private api: ApiService,
		public service: QuoteService
	) {
		super();
	}

	open(mdata) {
		this.context = 'all';
		this.projectId = null;
		this.quoteId = null;
		this.selectedType = null;

		if (mdata) {
			this.context = mdata.context;
		} else {
			this.context = 'all';
		}

		switch (this.context) {
			case 'project':
				// Export quotes from a project
				this.title = 'Export Quotes From Project';
				this.projectId = mdata.id;
				this.api.project.invoicing({ id: mdata.id }, data => {
					this.data = data;
				}, error => {
					this.app.notifications.showDanger(error.message);
				});
				break;

			case 'quote':
				// Export a single quote
				this.title = 'Export ' + this.service.moduleMap[this.app.routeData.module];
				this.quoteId = mdata.id;
				break;

			case 'all':
				// Exporting all quotes, resolve dates
				this.title = 'Export ' + this.service.moduleMap[this.app.routeData.module] + 's';
				this.api.quote.earliestQuoteDate(start => {
					this.start = MySQLDateToISOPipe.stringToDate(start);
				}, error => {
					this.app.notifications.showDanger(error.message);
					return;
				});
				break;

			default:
				// Invalid context
				this.app.notifications.showDanger('Nothing to export.');
				return;
		}

		this.modal.open();
	}

	generate() {
		const currentTimestamp = new Date().toISOString();

		switch (this.context) {
			case 'project':
				if (!this.checkType()) {
					this.app.notifications.showDanger('No data to export');
					this.modal.close();
					return;
				}
				window.open('/api/' + this.app.org + '/content/print_project_csv?context=project&id=' + this.projectId + '&type=' + this.selectedType + '&timestamp=' + encodeURIComponent(currentTimestamp));
				this.modal.close();
				break;

			case 'quote':
				window.open('/api/' + this.app.org + '/content/print_project_csv?context=quote&id=' + this.quoteId + '&type=' + this.selectedType + '&timestamp=' + encodeURIComponent(currentTimestamp));
				this.modal.close();
				break;

			case 'all':
				window.open('/api/' + this.app.org + '/content/print_project_csv?context=all&type=' + this.selectedType + '&start=' + MySQLDateToISOPipe.dateToString(this.start) + '&end=' + MySQLDateToISOPipe.dateToString(this.end) + '&timestamp=' + encodeURIComponent(currentTimestamp));
				this.modal.close();
				break;
		}
	}

	shouldSubmit() {
		return !!this.selectedType;
	}

	checkType() {
		switch (this.selectedType) {
			case 'quotes':
				return this.data.quotes.length > 0;
			case 'lines-limited-fields':
			case 'lines-all':
			case 'lines':
				return this.data.products_total > 0;
			case 'labour':
			case 'labour-by-type':
				return this.data.labour_total > 0;
		}
	}

}
